.full {
  padding-left: 0;
  padding-right: 0;
}

.wide {
  padding-left: 16px;
  padding-right: 16px;
}

.wideInner {
  max-width: auto;
}

.medium {
  padding-left: 16px;
  padding-right: 16px;
}

.mediumInner {
  max-width: 1760px;
}

.narrow {
  padding-left: 16px;
  padding-right: 16px;
}

.narrowInner {
  max-width: 1168px;
  margin-left: auto;
  margin-right: auto;
}

@media (--desktop) {
  .wide {
    padding-left: 80px;
    padding-right: 80px;
  }

  .medium {
    padding-left: 80px;
    padding-right: 80px;
  }

  .narrow {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (--desktop-m) {
  .wide {
    margin-top: 16px;
  }

  .narrow {
    padding-left: 188px;
    padding-right: 188px;
  }
}

@media (--desktop-xl) {
  .medium {
    padding-left: 228px;
    padding-right: 228px;
  }

  .narrow {
    padding-left: 376px;
    padding-right: 376px;
  }

  .wideInner,
  .mediumInner,
  .narrowInner {
    max-width: 100%;
  }
}
