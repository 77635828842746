.wrapper {
  position: relative;
}

.input {
  --input-stroke-width: 2px;

  grid-column-gap: 8px;
  padding: var(--input-padding-default);
}

.input input {
  outline: none;
}

.dropdown {
  position: relative;
  z-index: 999;
  left: 0;
  right: 0;
  max-height: var(--select-popup-max-height);
  overflow-y: auto;
  overflow-anchor: none;
  background-color: white;
  box-shadow: var(--select-popup-box-shadow);
  padding: 8px;
  border-radius: 8px;
}

.dropdown button {
  width: 100%;
  padding: 8px;
  margin-bottom: 4px;
  border-radius: 4px;
  cursor: pointer;
  text-align: left;
  font-size: var(--select-popup-font-size);
  font-weight: var(--select-popup-option-font-weight);
  line-height: var(--select-popup-option--line-height);
}

.dropdown button:hover,
.dropdown button.focused {
  background-color: var(--select-popup-option-bg-color-selected);
}
