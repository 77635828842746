.wrapper {
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-border-primary);
  overflow: hidden;
  width: 100%;
}

.default {
  color: var(--color-typography-heading);
  border-color: var(--color-border-primary);
  background-color: white;
}

.primary {
  color: var(--color-state-success);
  border-color: var(--color-state-success);
  background-color: var(--primary-lime-100);
}

.secondary {
  color: var(--color-state-secondary);
  border-color: var(--card-color-border-secondary);
  background-color: var(--card-color-bg-secondary);
}

.info {
  color: var(--primary-cyan-500);
  border-color: var(--primary-cyan-500);
  background-color: var(--primary-cyan-100);
}

.light {
  color: var(--color-typography-body);
  border-color: var(--color-border-primary);
  background-color: var(--list-details-color-bg-summary);
}

.content {
  color: var(--color-typography-body);
}

.link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.linkArrow {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.cardButton {
  --btn-size-md-height: 24px;
  --btn-icon-size: 24px;
}
